






import { Component, Prop, Vue } from 'vue-property-decorator'
import { logger } from '../../../support'

const marked = require('marked')

/**
 * @emits mounted (el: Element) - When the component gets mounted.
 *   @param el - Component's root element.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<UiMarkdown>({
  name: 'UiMarkdown',
  mounted (): void {
    this.$emit('mounted', this.$el)
  }
})
export class UiMarkdown extends Vue {
  /**
   * Markdown content to render.
   */
  @Prop({ type: String, required: true })
  private readonly content!: string

  /**
   * Determines whether the passed-in content should be parsed on inline,
   * without creating additional wrapping HTML elements.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly inline!: boolean

  /**
   * `content` prop parsed from MD to HTML.
   */
  public get html (): string {
    try {
      return this.inline ? marked.parseInline(this.content) : marked.parse(this.content)
    } catch (error) {
      logger(error, 'error')
      return this.content
    }
  }

  /**
   * An HTML tag that should be used as a component's root.
   */
  public get tag (): string {
    return this.inline ? 'span' : 'div'
  }
}

export default UiMarkdown
